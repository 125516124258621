.project-settings {
	position: relative;
	padding-top: 16px;
	padding-bottom: 80px;
	width: 100%;
	display: flex;

	&__loader {
		position: absolute;
		top: 250px;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 28px;
		z-index: 9;
	}

	&__content-wrapper {
		margin-left: 32px;
		margin-top: 4px;
		width: 100%;
		height: 100%;
	}

	//Overwrites
	.tab-navigation__tabs--vertical {
		width: 168px;
		margin-right: 0;
		transition: all 0.3s ease;

		.tab-navigation__tab {
			width: 100%;
		}

		@include app-grid-small {
			width: 128px;
		}
	}

	@import "./apiAccess/APIAccess";
	@import "./billingAndInvoice/BillingAndInvoice";
	@import "./labels/Labels";
	@import "./members/MembersTable/MembersTable";
	@import "./members/MembersSubheader/MembersSubheader";
	@import "./members/Members";
	@import "./planAndUsage/choosePlan/ChoosePlan";
	@import "./planAndUsage/PlanAndUsage";
	@import "./planAndUsage/subscriptionSettings/SubscriptionSettings";
	@import "./projectDetails/ProjectDetails";
}
