.member-form {
	&__label {
		@include font-base-14($n-17, true);
	}

	&__email {
		display: flex;
		width: 100%;

		&--no-label {
			.form-field__label-wrapper {
				margin-bottom: 4px;
			}
		}
	}

	&__inputs {
		width: 100%;
		display: flex;
		justify-content: space-between;

		.form-field {
			width: 100%;

			&__error-field {
				margin-top: 8px;
				margin-bottom: 8px;
				align-items: flex-start;
			}
		}

		&:last-of-type {
			.form-field__error-field {
				margin-bottom: 12px;
			}
		}
	}

	&__role-remove {
		margin-left: 8px;
		padding: 6px;

		&--with-label {
			margin-top: 28px;
		}

		svg {
			margin: 0;

			path {
				fill: $p-48;
			}
		}

		&:disabled {
			svg path {
				fill: $n-60;
			}
		}
	}

	&__add-another {
		padding: 2px 8px 2px 4px;
		height: 24px;

		svg {
			path {
				fill: $p-48;
			}
		}
	}

	&__title {
		@include font-base-22($n-17, true);
	}

	&__role {
		position: relative;
		padding-left: 16px;
		padding-top: 4px;
		width: 250px;
		display: flex;

		&--with-label {
			padding-top: 0;
		}

		&--no-label {
			padding-top: 4px;
		}

		select {
			padding: 10px 16px;
			font-weight: 700;
			color: $n-17;
			font-family: inherit;
		}

		.dropdown__list {
			width: 254px;
		}

		.dropdown__list-item-left {
			width: 100%;
		}

		.dropdown__error {
			word-break: break-word;
			align-items: flex-start;
			margin-top: 8px;
		}
	}

	&__cancel-btn {
		margin-right: 8px;
	}

	.form-field__error-message {
		word-break: break-word;
	}
}
