.members {
	&__search {
		position: relative;

		.form-field__label-wrapper {
			margin-bottom: 0;
		}

		input {
			padding: 7px 32px 7px 36px;
			width: 268px;
			border: 1px solid $n-75;
		}
	}

	&__add-member-btn {
		padding: 6px 12px 6px 8px;
		position: relative;
		white-space: nowrap;

		svg {
			margin-right: 8px;
		}
	}

	&__close-icon {
		position: absolute;
		top: 10px;
		right: 12px;
		cursor: pointer;

		&:hover path {
			fill: $n-35;
		}
	}

	&__search-icon {
		position: absolute;
		top: 8px;
		left: 12px;
		cursor: pointer;
	}

	.form-field__error-field {
		margin-top: 0;
	}

	.info-text {
		margin: 16px 0;
		text-align: center;

		a {
			text-decoration: none;
			@include font-base-14($p-48, true);

			div {
				margin-top: 8px;
				display: flex;
				justify-content: center;
			}

			svg {
				margin-right: 5px;
			}
		}
	}
}

@import "./membersForm/MemberForm";
