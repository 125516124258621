.members-row {
	&__avatar-wrapper {
		display: flex;
		align-items: center;

		.avatar {
			height: 34px;
			width: 34px;
		}
	}

	&__member-data {
		display: flex;
		align-items: center;
		column-gap: 8px;
		@include font-base-14($n-17);

		.label {
			height: 20px;
			font-weight: 700;
			border-radius: 16px;
		}

		p {
			@include font-base-14($n-17);
		}
	}

	&__personal-info-wrapper {
		height: 40px;
		display: grid;
		justify-content: left;
		align-items: center;
	}

	&__member-email,
	&__member-name {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	&__role {
		width: 196px;
	}

	&__list {
		.table-wrapper {
			overflow: unset;
		}
	}

	&__role-value {
		text-transform: capitalize;
	}

	&__modal-cell {
		padding: 0px !important;
	}

	&__leave-project {
		width: 136px;

		button {
			background-color: transparent;
			white-space: nowrap;

			&:hover {
				background-color: $p-90;
			}

			svg {
				margin-right: 8px;

				path {
					fill: $p-48;
				}
			}

			&:disabled {
				svg path {
					fill: $n-60;
				}
			}
		}
	}
}
