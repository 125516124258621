.members-subheader {
	margin-bottom: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__right {
		display: flex;
		align-items: center;
		column-gap: 8px;
	}

	&__invite {
		white-space: nowrap;

		&:disabled svg path {
			fill: $n-60;
		}
	}

	.dropdown {
		width: 188px;
	}
}
