.members-table {
	overflow: unset;

	&__loader-wrapper {
		display: flex;
		align-items: center;
		height: 64px;
	}

	.table__header .table__head:first-child {
		border-top-left-radius: 8px;
	}

	.table__header .table__head:last-child {
		border-top-right-radius: 8px;
	}

	&__no-results {
		padding: 18px 16px !important;
		@include font-base-14($n-17);
	}

	th {
		white-space: nowrap;
	}

	.table__footer .table__cell {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}

@import "MembersRow/MembersRow";
